<pw-view-title>
  {{ 'planning.bookings.title' | translate }}
</pw-view-title>

<pw-view-actions>
  <button mat-icon-button (click)="toggleSort()">
    <mat-icon style="transform: rotate(180deg)" *ngIf="sortOrder === 'asc'"
      >sort
    </mat-icon>
    <mat-icon *ngIf="sortOrder === 'desc'">sort</mat-icon>
  </button>
</pw-view-actions>

<div
  style="display: flex; justify-content: center; padding: 8px"
  *ngIf="!(isMobile$ | async)">
  <mat-button-toggle-group
    [formControl]="filterModeControl"
    name="fontStyle"
    aria-label="Font Style">
    <mat-button-toggle value="last12"
      >{{ 'planning.booking.today' | translate }}
    </mat-button-toggle>
    <mat-button-toggle value="lastWeek"
      >{{ 'planning.booking.week' | translate }}
    </mat-button-toggle>
    <mat-button-toggle value="lastMonth"
      >{{ 'planning.booking.month' | translate }}
    </mat-button-toggle>
    <mat-button-toggle value="date"
      >{{ 'planning.booking.date' | translate }}
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>

<ng-container *ngIf="currentBookings$ | async as bookings">
  <div style="padding: 1rem" *ngIf="bookings.length > 0">
    <mat-accordion>
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-title
            >{{ 'planning.booking.activeBooking' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="p4m-grid-3-col" *ngIf="bookings[0]" style="width: 100%">
          <mat-form-field>
            <mat-label>{{ 'planning.booking.task' | translate }}</mat-label>
            <input matInput [(ngModel)]="bookings[0].AFONAME" readonly />
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'planning.booking.resource' | translate }}</mat-label>
            <input matInput [(ngModel)]="bookings[0].RESNAME" readonly />
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'planning.booking.tool' | translate }}</mat-label>
            <input matInput [(ngModel)]="bookings[0].TOOLNAME" readonly />
          </mat-form-field>

          <div
            style="
              grid-column: auto / span 3;
              display: flex;
              justify-content: end;
            ">
            <button
              mat-icon-button
              color="accent"
              (click)="stopBooking(bookings[0])">
              <mat-icon>stop</mat-icon>
            </button>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</ng-container>

<!-- desktop view -->
<div class="p4m-grid-2-col" *ngIf="!(isMobile$ | async); else mobile">
  <div
    style="grid-column: auto / span 2"
    *ngIf="filterModeControl.getRawValue() === 'date'">
    {{ filterStart | date: 'shortDate' }} -
    {{ filterEnd | date: 'shortDate' }}
    <button
      mat-raised-button
      color="accent"
      style="margin-left: 12px"
      (click)="openFilterBs()">
      {{ 'planning.booking.select' | translate }}
    </button>
  </div>
  <div>
    <div class="mat-elevation-z6" *ngFor="let booking of bookings$ | async">
      <p4m-booking-component
        [booking]="booking"
        (bookingChanged)="onBookingChanged($event)"></p4m-booking-component>
      <mat-divider vertical="false"></mat-divider>
    </div>
  </div>
  <div class="p4m-grid-2-col" style="align-content: start">
    <div class="sum-card mat-elevation-z6" style="grid-column: auto / span 2">
      <h3>{{ 'planning.booking.trackedHours' | translate }}</h3>
      <div style="color: #2196f3">
        <span style="font-weight: bold">Mitarbeiter: </span>
        {{ getSumEmployees() | async | number }}
        {{ 'planning.booking.trackedHours' | translate }}
      </div>
      <div style="color: #689f38">
        <span style="font-weight: bold">Maschine: </span>
        {{ getSumMachines() | async | number }}
        {{ 'planning.booking.trackedHours' | translate }}
      </div>
    </div>
    <div
      class="mat-elevation-z6"
      style="grid-column: auto / span 2; height: fit-content">
      <highcharts-chart
        [options]="chartOptions"
        [Highcharts]="Highcharts"></highcharts-chart>
    </div>
  </div>
</div>

<!-- mobile view -->
<ng-template #mobile>
  <div class="mobile-view-container">
    <div
      style="
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 8px;
      "
      *ngIf="isMobile$ | async">
      <mat-button-toggle-group
        [formControl]="filterModeControl"
        style="flex-wrap: wrap">
        <mat-button-toggle value="last12"
          >{{ 'planning.booking.today' | translate }}
        </mat-button-toggle>
        <mat-button-toggle value="lastWeek"
          >{{ 'planning.booking.week' | translate }}
        </mat-button-toggle>
        <mat-button-toggle value="lastMonth"
          >{{ 'planning.booking.month' | translate }}
        </mat-button-toggle>
        <mat-button-toggle value="date"
          >{{ 'planning.booking.date' | translate }}
        </mat-button-toggle>
      </mat-button-toggle-group>
      <div
        *ngIf="filterModeControl.getRawValue() === 'date'"
        style="
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          padding: 12px;
        ">
        {{ filterStart | date: 'shortDate' }} -
        {{ filterEnd | date: 'shortDate' }}
        <button
          mat-raised-button
          color="accent"
          style="margin-left: 12px"
          (click)="openFilterBs()">
          {{ 'planning.booking.select' | translate }}
        </button>
      </div>
    </div>
    <div class="mobile-bookings mat-elevation-z6">
      <div class="" *ngIf="(bookings$ | async)?.length > 0; else nobookings">
        <ng-container *ngFor="let booking of bookings$ | async">
          <p4m-booking-component
            (bookingChanged)="onBookingChanged($event)"
            [booking]="booking"></p4m-booking-component>
          <mat-divider vertical="false"></mat-divider>
        </ng-container>
      </div>
      <ng-template #nobookings>
        <div>{{ 'planning.booking.noBookings' | translate }}</div>
      </ng-template>
    </div>
    <div class="mobile-sum mat-elevation-z6" *ngIf="bookings$">
      <mat-icon>functions</mat-icon>
      <div style="padding-left: 12px; font-weight: bold">
        <div *ngIf="getSumEmployees() | async as sum">
          {{ 'planning.booking.employee' | translate }}: {{ sum | number }}
          h
        </div>
        <div *ngIf="getSumMachines() | async as sum">
          {{ 'planning.booking.machine' | translate }}: {{ sum | number }}h
        </div>
      </div>
      <div>
        {{ filterStart | date: 'shortDate' }} -
        {{ filterEnd | date: 'shortDate' }}
      </div>
    </div>
  </div>
</ng-template>
