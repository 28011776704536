import { EventEmitter, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProleisForMobileState } from '../../../../store/store.index';
import {
  CoreService,
  ProleisObject,
  Resource,
  Task,
} from 'proleis-rest-client';
import { PlanningActions } from '../../../../store/planning/planning.index';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  exhaustMap,
  iif,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import {
  selectPpsFilter,
  selectPpsResourceInfo,
  selectPpsResources,
} from '../../../../store/planning/planning.selectors';
import {
  PpsFilter,
  PpsResource,
} from '../../../../store/planning/planning.state';
import { ConfirmDialogComponent } from '../../../../general/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { GUID_TYPE_TASK } from '../../../../../p4m/guids';

@Injectable({
  providedIn: 'root',
})
export class PpsService {
  ppsResource$: Observable<PpsResource>;
  selectedResource$: BehaviorSubject<Resource> = new BehaviorSubject<Resource>(
    undefined,
  );
  selectedResId: string;
  resources$: Observable<Resource[]>;
  todoTasks$: Observable<Task[]>;
  inProgressTasks$: Observable<Task[]>;
  finishedTasks$: Observable<Task[]>;
  taskFilter$: Observable<string>;
  ppsFilter: Observable<PpsFilter>;

  constructor(
    private store: Store<ProleisForMobileState>,
    private dialog: MatDialog,
    private cs: CoreService,
  ) {
    if (this.selectedResource$) {
      this.selectedResource$.subscribe((res) => {
        if (res) {
          this.selectedResId = res.OBJECT_ID;
        }
      });
    }
    this.ppsFilter = this.loadPpsFilter();
  }

  loadResources() {
    this.store.dispatch(PlanningActions.loadPpsResources());
    this.resources$ = this.store.select(selectPpsResources).pipe(
      distinctUntilChanged(),
      tap((res) => {
        const lastSelectedRes = localStorage.getItem('p4m-last-selected-res');
        if (lastSelectedRes) {
          this.resourceChangedHandler(
            res.find((r) => r.OBJECT_ID === lastSelectedRes),
          );
        } else {
          this.resourceChangedHandler(res[0]);
        }
      }),
    );
  }

  loadTasks() {
    if (this.selectedResId) {
      this.store.dispatch(
        PlanningActions.loadPpsResourceInfoState({ resId: this.selectedResId }),
      );
      this.ppsResource$ = this.store.select(
        selectPpsResourceInfo,
        this.selectedResId,
      );
      this.ppsResource$ = combineLatest([
        this.ppsResource$,
        this.taskFilter$,
      ]).pipe(
        exhaustMap(([data, filterExpression]) => {
          return iif(
            () => filterExpression === '',
            of(data),
            this.createFilteredInfo(data, filterExpression),
          );
        }),
        distinctUntilChanged(),
      );

      if (this.ppsResource$) {
        this.filterTasksByStatus(this.selectedResId);
      }
    }
  }

  setFilter(filter$: Observable<string>) {
    this.taskFilter$ = filter$;
  }

  resourceChangedHandler(res: Resource) {
    if (res) {
      this.selectedResource$.next(res);
      localStorage.setItem('p4m-last-selected-res', res.OBJECT_ID);
      this.loadTasks();
    }
  }

  setTaskInProgress(taskId: string) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: 'Vorgang in Arbeit setzen',
          message: 'Möchten Sie den Vorgang nun in Arbeit setzen?',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.store.dispatch(
            PlanningActions.setTaskInProgress({
              taskId,
              resId: this.selectedResId,
            }),
          );
        }
      });
  }

  setTaskComplete(taskId: string) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: 'Vorgang fertigmelden',
          message: 'Möchten Sie den Vorgang nun fertigmelden?',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.store.dispatch(
            PlanningActions.setTaskComplete({
              taskId,
              resId: this.selectedResId,
            }),
          );
        }
      });
  }

  interruptTask(taskId: string) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: 'Vorgang unterbrechen',
          message: 'Möchten Sie den Vorgang unterbrechen?',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.store.dispatch(
            PlanningActions.setTaskInterrupted({
              taskId,
              resId: this.selectedResId,
            }),
          );
        }
      });
  }

  isFinished(task: Task) {
    return task.ISTBEARBEITET;
  }

  isInProgress(task: Task) {
    return task.ISTINARBEIT && !task.ISTBEARBEITET;
  }

  isNotReleased(task: Task) {
    return (
      task.FREIGABE_STATUS === 0 &&
      !this.isInProgress(task) &&
      !this.isFinished(task)
    );
  }

  isPartiallyReleased(task: Task) {
    return (
      task.FREIGABE_STATUS < 100 &&
      task.FREIGABE_STATUS !== 0 &&
      !this.isFinished(task) &&
      !this.isInProgress(task)
    );
  }

  isReady(task: Task) {
    return (
      !this.isInProgress(task) &&
      !this.isPartiallyReleased(task) &&
      !this.isNotReleased(task) &&
      !this.isFinished(task)
    );
  }

  isTask(objectId: string): Observable<boolean> {
    return this.cs
      .getObjectById<ProleisObject>(objectId, 'TYP_GUID=TYP_ID.GUID')
      .pipe(
        map((obj) => {
          return obj.TYP_GUID === GUID_TYPE_TASK;
        }),
      );
  }

  loadPpsFilter(): Observable<PpsFilter> {
    this.store.dispatch(PlanningActions.loadPpsFilter());
    return this.store.select(selectPpsFilter);
  }

  setPpsFilter(filter: PpsFilter) {
    this.selectedResource$.subscribe((res) => {
      this.store.dispatch(
        PlanningActions.setPpsFilter({
          end: filter.end ? filter.end : null,
          start: filter.start ? filter.start : null,
          lead: filter.lead,
          onlyReleased: filter.onlyReleased,
          includeFinished: filter.includeFinished,
        }),
      );
    });
  }

  private createFilteredInfo(
    data: PpsResource,
    filterExpression: string,
  ): Observable<PpsResource> {
    if (data) {
      const result = {
        resId: data.resId,
        tasks: data.tasks.filter(
          (task) =>
            task.NAME.toUpperCase().includes(filterExpression.toUpperCase()) ||
            task.TEXT.toUpperCase().includes(filterExpression.toUpperCase()),
        ),
      };
      return of(result);
    }
    return null;
  }

  private filterTasksByStatus(resId: string) {
    this.todoTasks$ = this.ppsResource$.pipe(
      switchMap((data) => {
        if (data && data.tasks) {
          return of(
            data.tasks.filter((task) => {
              return !task.ISTBEARBEITET && !task.ISTINARBEIT;
            }),
          );
        }
        return of([]);
      }),
    );

    this.inProgressTasks$ = this.ppsResource$.pipe(
      switchMap((data) => {
        if (data && data.tasks) {
          return of(
            data.tasks.filter((task) => {
              return task.ISTINARBEIT && !task.ISTBEARBEITET;
            }),
          );
        }
        return of([]);
      }),
    );

    this.finishedTasks$ = this.ppsResource$.pipe(
      switchMap((data) => {
        if (data && data.tasks) {
          return of(
            data.tasks.filter((task) => {
              return task.ISTBEARBEITET;
            }),
          );
        }
        return of([]);
      }),
    );
  }
}
