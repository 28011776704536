import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProleisForMobileState } from '../../../../store/store.index';
import { PlanningActions } from '../../../../store/planning/planning.index';
import { Observable } from 'rxjs';
import { ProleisObject } from 'proleis-rest-client/lib/models/proleis-object-models/proleis.object';
import { selectUserBookings } from '../../../../store/planning/planning.selectors';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { UserService } from 'proleis-rest-client';
import { FormControl } from '@angular/forms';
import { BreakpointService } from 'proleis-web-app';

import * as Highcharts from 'highcharts';
import { Options, SeriesOptionsType } from 'highcharts';
import { DatePipe } from '@angular/common';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { EditFilterBottomsheetComponent } from './edit-filter.bottomsheet/edit-filter.bottomsheet.component';
import { GUID_TYPE_PSE } from '../../../../../p4m/guids';

@Component({
  selector: 'p4m-user-bookings',
  templateUrl: 'user.bookings.component.html',
  styleUrls: ['user.bookings.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserBookingsComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;
  currentBookings$: Observable<ProleisObject[]>;
  bookings$: Observable<ProleisObject[]>;
  filterStart: Date;
  filterEnd: Date;
  searchSelectionPosition: ProleisObject;
  searchTypes: string[];
  sortOrder = 'desc';
  filterModeControl = new FormControl('last12');
  isMobile$: Observable<boolean>;
  chartOptions: Options;

  constructor(
    private store: Store<ProleisForMobileState>,
    private router: Router,
    private us: UserService,
    private bs: BreakpointService,
    private datePipe: DatePipe,
    private bottomSheet: MatBottomSheet,
  ) {}

  ngOnInit() {
    this.isMobile$ = this.bs.isSmall();
    this.searchTypes = [];
    this.searchTypes.push(GUID_TYPE_PSE);
    this.filterModeControl.valueChanges.subscribe((value) => {
      this.filterEnd = new Date();
      if (value === 'last12') {
        this.filterStart = new Date(new Date().setHours(0, 0, 0));
      }
      if (value === 'lastWeek') {
        this.filterStart = new Date(
          new Date(
            new Date().setDate(
              new Date().getDate() - ((new Date().getDay() + 6) % 7),
            ),
          ).setHours(0, 0, 0),
        );
      }
      if (value === 'lastMonth') {
        this.filterStart = new Date(new Date().setDate(1));
      }

      this.loadBookings();

      this.bookings$.subscribe((bookings) => {
        this.buildChart(bookings);
      });
    });
    this.filterModeControl.setValue('last12');

    this.getCurrentBooking();
  }

  loadBookings() {
    const aliasFields =
      'RES_NAME=RESOURCE_ID.NAME;AFOBEZ=OBJECT_ID.AFOBEZ;AUFTRAG=AUFTRAG_ID.TEXT;' +
      'PROJECT_NAME=BEMI_OBJ_ID.PARENT[TYP_ID.GUID=b514d7ec-27fa-11b2-800a-b390cd5bbe84].NAME' +
      ';AFO_DESCR=OBJECT_ID.TEXT;POS_TEXT=BEMIPOS_OBJ_ID.TEXT;AUFTR_NAME=AUFTRAG_ID.NAME;AUFTR_DESC=AUFTRAG_ID.DESCRIPTION;' +
      'INCIDENT_NAME=VORFALL_ID.TEXT';
    this.store.dispatch(
      PlanningActions.loadUserBookings({
        aliasFields,
        start: this.filterStart,
        end: this.filterEnd,
      }),
    );
    this.bookings$ = this.store
      .select(selectUserBookings)
      .pipe(map((bookings) => this.sortBookings(bookings)));
  }

  onBookingSelected(booking: ProleisObject) {
    this.router.navigate([
      '/general/info-panels/booking',
      booking.OBJECT_ID,
      booking.BDE_BUCHUNG_ID,
    ]);
  }

  onSearch(event: ProleisObject) {
    if (!event) {
      this.searchSelectionPosition = null;
    } else {
      this.searchSelectionPosition = event;
    }
  }

  filter() {
    const aliasFields =
      'RES_NAME=RESOURCE_ID.NAME;AFO_NAME=AFO_ID.AFOBEZ;' +
      'PROJECT_NAME=BEMI_OBJ_ID.PARENT[TYP_ID.GUID=b514d7ec-27fa-11b2-800a-b390cd5bbe84].NAME' +
      ';AFO_DESCR=OBJECT_ID.TEXT';
    this.store.dispatch(
      PlanningActions.loadUserBookings({
        start: this.filterStart,
        end: this.filterEnd,
        aliasFields,
      }),
    );
    this.sort();
  }

  sort() {
    this.bookings$ = this.store
      .select(selectUserBookings)
      .pipe(map((bookings) => this.sortBookings(bookings)));
  }

  getSumEmployees(): Observable<number> {
    return this.bookings$.pipe(
      map((bookings) => {
        let sum = 0;
        if (bookings) {
          bookings
            .filter((booking) => booking.IST_MANNLOS === false)
            .forEach((booking) => (sum += booking.STUNDEN));
        }
        return sum;
      }),
    );
  }

  getSumMachines(): Observable<number> {
    return this.bookings$.pipe(
      map((bookings) => {
        let sum = 0;
        if (bookings) {
          bookings
            .filter((booking) => booking.IST_MANNLOS === true)
            .forEach((booking) => (sum += booking.STUNDEN));
        }
        return sum;
      }),
    );
  }

  sortBookings(bookings: ProleisObject[]) {
    let sorted;
    if (this.sortOrder === 'desc') {
      sorted = bookings
        .slice()
        .sort(
          (a, b) =>
            new Date(b.DATUM_BUCHUNG).getTime() -
            new Date(a.DATUM_BUCHUNG).getTime(),
        );
    } else {
      sorted = bookings
        .slice()
        .sort(
          (a, b) =>
            new Date(a.DATUM_BUCHUNG).getTime() -
            new Date(b.DATUM_BUCHUNG).getTime(),
        );
    }
    return sorted;
  }

  toggleSort() {
    if (this.sortOrder === 'asc') {
      this.sortOrder = 'desc';
    } else {
      this.sortOrder = 'asc';
    }
    this.sort();
  }

  openFilterBs() {
    this.bottomSheet
      .open(EditFilterBottomsheetComponent)
      .afterDismissed()
      .subscribe((result) => {
        if (result) {
          this.filterStart = result.start;
          this.filterEnd = result.end;
          this.filter();
        }
      });
  }

  buildChart(bookings: ProleisObject[]) {
    const categories = [
      ...new Set(
        bookings
          .map((booking) =>
            this.datePipe.transform(booking.DATUM_BUCHUNG, 'shortDate'),
          )
          .sort(),
      ),
    ];
    const machineBookings = bookings.filter(
      (booking) => booking.IST_MANNLOS === true,
    );
    const employeeBookings = bookings.filter(
      (booking) => booking.IST_MANNLOS === false,
    );
    const series: SeriesOptionsType[] = [];

    series.push({
      name: 'Mitarbeiter',
      data: categories.map((c) => {
        return employeeBookings
          .filter((booking) => {
            return (
              this.datePipe.transform(booking.DATUM_BUCHUNG, 'shortDate') === c
            );
          })
          .reduce((arr, curr) => {
            return arr + curr.STUNDEN;
          }, 0);
      }),
      type: 'column',
      color: '#2196F3',
    });

    series.push({
      name: 'Maschine',
      data: categories.map((c) => {
        return machineBookings
          .filter((booking) => {
            return (
              this.datePipe.transform(booking.DATUM_BUCHUNG, 'shortDate') === c
            );
          })
          .reduce((arr, curr) => {
            return arr + curr.STUNDEN;
          }, 0);
      }),
      type: 'column',
      color: '#689F38',
    });

    this.chartOptions = {
      credits: {
        enabled: false,
      },
      chart: {
        type: 'column',
      },
      title: {
        text: 'Gebuchte Stunden pro Tag',
      },
      xAxis: {
        categories,
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Stunden',
        },
      },
      series,
    };
  }

  getCurrentBooking() {
    const aliasFields =
      'AFONAME=AFO_ID.TEXT;TOOLNAME=BEMI_ID.TEXT;RESNAME=RES_ID.TEXT';
    this.currentBookings$ = this.us.getCurrentBookings(aliasFields);
  }

  stopBooking(booking: ProleisObject) {
    this.us.stopTimeTracking(booking.AFO_ID).subscribe(() => {
      this.getCurrentBooking();
    });
  }

  onBookingChanged(id: string) {
    this.loadBookings();
  }
}
