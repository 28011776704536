import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTab, MatTabChangeEvent } from '@angular/material/tabs';
import { Resource } from 'proleis-rest-client';
import { enterLeave } from 'proleis-web-animations';
import { BreakpointService, CurrentViewService } from 'proleis-web-app';
import { debounceTime, map, Observable, startWith, tap } from 'rxjs';
import { PpsService } from './pps.service';
import { PpsFilter } from '../../../../store/planning/planning.state';

@Component({
  selector: 'p4m-pps-board',
  templateUrl: 'pps.board.component.html',
  styleUrls: ['pps.board.component.scss'],
  animations: [enterLeave],
  encapsulation: ViewEncapsulation.None,
})
export class PpsBoardComponent implements OnInit {
  searchFormControl: FormControl;

  filterStart: Date;
  filterEnd: Date;
  filterFinished: boolean;
  filterOnlyReleased: boolean;
  filterLead: number;
  filterStartRange: Date;
  filterEndRange: Date;

  filterChips: PpsFilterChipItem[] = [];

  isMobile: boolean;

  constructor(
    private cvs: CurrentViewService,
    public ppsService: PpsService,
    private bs: BreakpointService,
  ) {}

  ngOnInit() {
    this.initializeTaskFilter();
    this.ppsService.loadResources();
    this.loadFilterSettings();
    this.bs.isSmall().subscribe((isSmall) => {
      this.isMobile = isSmall;
    });
  }

  loadFilterSettings() {
    this.ppsService.loadPpsFilter().subscribe((filterSettings) => {
      this.filterStartRange = filterSettings?.rangeStart;
      this.filterStart = filterSettings?.start;
      this.filterEndRange = filterSettings?.rangeEnd;
      this.filterEnd = filterSettings?.end;
      this.filterFinished = filterSettings?.includeFinished;
      this.filterOnlyReleased = filterSettings?.onlyReleased;
      this.filterLead = filterSettings?.lead;
      this.createFilterChips(filterSettings);
    });
  }

  createFilterChips(filterSettings: PpsFilter) {
    this.filterChips = [];
    if (this.filterStart) {
      this.filterChips.push({
        name: 'Start',
        value: filterSettings.start.toLocaleDateString(),
        filterField: 'START',
        required: false,
      });
    }
    if (this.filterEnd) {
      this.filterChips.push({
        name: 'Ende',
        value: filterSettings.end.toLocaleDateString(),
        filterField: 'END',
        required: false,
      });
    }
    if (this.filterLead) {
      this.filterChips.push({
        name: 'Lead',
        value: String(filterSettings.lead),
        filterField: 'LEAD',
        required: false,
      });
    }
    if (this.filterOnlyReleased) {
      this.filterChips.push({
        name: 'Freigegeben',
        value: String(filterSettings.onlyReleased),
        filterField: 'RELEASED',
        required: false,
      });
    }
    if (this.filterFinished) {
      this.filterChips.push({
        name: 'Fertige',
        value: String(filterSettings.includeFinished),
        filterField: 'FINISHED',
        required: false,
      });
    }
  }

  initializeTaskFilter() {
    this.searchFormControl = new FormControl<any>('');
    const filter$: Observable<string> =
      this.searchFormControl.valueChanges.pipe(
        tap((value) => this.cvs.setLoading(true)),
        debounceTime(250),
        startWith(''),
      );
    this.ppsService.setFilter(filter$);
    this.ppsService.loadTasks();
  }

  onTabChanged(event: MatTabChangeEvent) {
    this.getTabRes(event.tab)
      .subscribe((res) => {
        this.ppsService.resourceChangedHandler(res);
      })
      .unsubscribe();
  }

  getTabRes(tab: MatTab): Observable<Resource> {
    const resName = tab.textLabel;
    return this.ppsService.resources$.pipe(
      map((resources) => resources.filter((res) => res.NAME === resName)[0]),
    );
  }

  toggleDrawer() {
    this.cvs.drawer.toggle().then();
  }

  setFilter() {
    this.ppsService.setPpsFilter({
      end: this.filterEnd,
      includeFinished: this.filterFinished,
      onlyReleased: this.filterOnlyReleased,
      lead: this.filterLead,
      start: this.filterStart,
      rangeEnd: this.filterEndRange,
      rangeStart: this.filterStartRange,
    });
    this.ppsService.loadResources();
  }

  cancel() {
    this.cvs.drawer.toggle();
  }

  onResourceChanged(event: any) {
    localStorage.setItem('pps_selected_res', event.value.OBJECT_ID);
    this.ppsService.resourceChangedHandler(event.value);
  }

  removeChip(name: string) {
    const index = this.filterChips.findIndex((chip) => chip.name === name);
    switch (this.filterChips[index]?.filterField) {
      case 'END':
        this.filterEnd = undefined;
        break;
      case 'START':
        this.filterStart = undefined;
        break;
      case 'FINISHED':
        this.filterFinished = undefined;
        break;
      case 'RELEASED':
        this.filterOnlyReleased = undefined;
        break;
      case 'LEAD':
        this.filterLead = undefined;
        break;
    }
    this.filterChips.splice(index, 1);
    this.setFilter();
  }
}

export type PpsFilterChipItem = {
  name: string;
  value: string;
  filterField: 'START' | 'END' | 'FINISHED' | 'RELEASED' | 'LEAD';
  required: boolean;
};
